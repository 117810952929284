<template>
  <div>
    <span>任务状态： </span>
    <el-select
      v-model="displayStatusList"
      style="width: 300px;"
      clearable
      multiple
      @change="setOptionUserInfo"
    >
      <el-option
        v-for="item in taskStatusListOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
        <span :style="item.color">{{ item.label }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import {getOptionUserInfo, setOptionUserInfo} from "@/api/calendar";

export default {
  name: "myMissionTaskStatusPreference",
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      optionName: 'task:my_mission:preference:task_status',
      displayStatusList: [5],
      taskStatusListOptions: [
        {
          value: 5,
          label: "进行中",
          color: "color:#F56C6C",
        },
        {
          value: 4,
          label: "待启动",
          color: "color:#409EFF",
        },
        {
          value: 6,
          label: "已完成",
          color: "color:#67C23A",
        },
      ],
    }
  },
  mounted() {
    this.getOptionUserInfo();
  },
  activated() { 
    this.getOptionUserInfo();
  },
  watch: {
    info: {
      handler(val, oldVal) {
        let optionValue = val[this.optionName];
        if (optionValue) {
          this.displayStatusList = JSON.parse(optionValue) || [];
          localStorage.setItem(this.optionName, optionValue);
        }
      },
      deep: true
    }
  },
  methods: {
    getOptionUserInfo() {
      let item = window.localStorage.getItem(this.optionName);
      if (item) {
        this.displayStatusList = JSON.parse(item) || [];
      }
    },
    setOptionUserInfo(val) {

      val = JSON.stringify(val);
      localStorage.setItem(this.optionName, val);

      let param = {
        name: this.optionName,
        value: val
      }
      setOptionUserInfo(param)
        .then(res => {
          if (res.success) {
            console.log('我的任务状态偏好保存成功');
          }
        })
    },
  }
}
</script>

<style scoped lang="scss">

</style>
