import { render, staticRenderFns } from "./userPreference.vue?vue&type=template&id=ed1b6f48&scoped=true&"
import script from "./userPreference.vue?vue&type=script&lang=js&"
export * from "./userPreference.vue?vue&type=script&lang=js&"
import style0 from "./userPreference.vue?vue&type=style&index=0&id=ed1b6f48&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed1b6f48",
  null
  
)

export default component.exports