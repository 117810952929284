import request from '@/utils/request';
import requestBlob from '@/utils/requestBlob';

import {queryInstitutionSelectList} from "@/api/institutionManage";
import {selectListMeetingProject} from "@/api/minutesOfMeeting";

//获取日程列表
export function getCalendarList(param){
    return request({
        url: '/admin/schedule/calendar/findList',
        method: 'post',
        data: param
    })
}

//添加日程
export function addCalendarThing(param){
    return request({
        url: '/admin/schedule/addThing',
        method: 'post',
        data: {...param}
    })
}

//编辑日程
export function editCalendarThing(param){
    return request({
        url: '/admin/schedule/updateThing',
        method: 'post',
        data: {...param}
    })
}

//更新日程时间
export function editCalendarTime(param){
    return request({
        url: '/admin/schedule/updateThingTime',
        method: 'put',
        data: {...param}
    })
}

//获取日程详情
export function getCalendarThingDetails(param){
    return request({
        url: '/admin/schedule/thing',
        method: 'post',
        data: {id: param}
    })
}

//删除日程
export function deleteCalendarThing(param){
    return request({
        url: '/admin/schedule/deleteThing',
        method: 'post',
        data: {id: param}
    })
}

//条件查询日程可见部门用户的下拉列表
export function getScheduleUseDeptList(){
  return request({
    url: '/admin/schedule/user/dept/selectList',
    method: 'get'
  })
}

//条件查询部门用户的下拉列表
export function getUseDeptList(param){
  return request({
    url: '/admin/system/user/dept/selectList',
    method: 'post',
    data: param
  })
}

//设置用户的选项
export function setOptionUserInfo(param){
    return request({
        url: '/admin/option/user/set',
        method: 'post',
        data: param
    })
}

//获取用户的选项
export function getOptionUserInfo(param){
    return request({
        url: '/admin/option/user/get',
        method: 'post',
        data: param
    })
}
//获取用户的选项
export function getAllOptionsUserInfo(){
    return request({
        url: '/admin/option/user/getAll',
        method: 'post'
    })
}

//获取用户的选项
export function getScheduleCalendarInfo(param){
    return request({
        url: '/admin/schedule/calendar/findDayDetail',
        method: 'post',
        data: param
    })
}

//条件查询日程的下拉列表
export function getCalendarScheduleSelectList(param){
    return request({
        url: '/admin/schedule/selectList',
        method: 'post',
        data: param
    })
}
//导出日程
export function exportCalendarSchedule(param){
    return requestBlob({
        url: '/admin/schedule/export',
        method: 'post',
        data: param
    })
}

// 获取日程关联对象列表
export async function getBusinessSelectList() {
  const projectResponse = await selectListMeetingProject({isVirtual: 0});
  const institutionResponse = await queryInstitutionSelectList({});

  const selectList = [];
  for (const project of projectResponse.data) {
    project.name = '项目-' + project.name;
    project.businessType = 2;
    selectList.push(project);
  }

  for (const institution of institutionResponse.data) {
    institution.name = '投资机构-' + institution.organizationName;
    institution.businessType = 3;
    selectList.push(institution);
  }

  return selectList;
}

//钉钉请假、外出、出差等预计算时长
export function calculateApproveDuration(params) {
  const {bizType, fromTime, toTime, durationUnit, calculateMode} = params;
  return request({
    url: `/admin/ding-talk/calculate-approve-duration?bizType=${bizType}&fromTime=${encodeURIComponent(fromTime)}&toTime=${encodeURIComponent(toTime)}&durationUnit=${durationUnit}&calculateMode=${calculateMode}`,
    method: 'get',
  })
}

//钉钉火车票城市搜索
export function getTrainCitySuggest(keyword) {
  return request({
    url: `/admin/ding-talk/get-train-city-suggest?keyword=${keyword}`,
    method: 'get'
  })
}

//钉钉机票城市搜索
export function getFlightCitySuggest(keyword, type) {
  return request({
    url: `/admin/ding-talk/get-flight-city-suggest?keyword=${keyword}&type=${type}`,
    method: 'get'
  })
}
