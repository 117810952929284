<template>
  <div style="height: 100%;">
    <el-row class="tac" style="height: 100%;">
      <el-col :span="4" style="position: absolute;">
        <el-menu
          :default-active="activeMenu"
          :default-openeds="['1']"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose">
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-s-home"></i><span>首页</span>
            </template>
            <el-menu-item index="1-1" @click="scrollToId('1-1')">日历</el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-tickets"></i><span>我的工作</span>
            </template>
            <el-menu-item index="2-1" @click="scrollToId('2-1')">我的任务</el-menu-item>
            <el-menu-item index="2-2" @click="scrollToId('2-2')">任务管理</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-col>

      <el-col :offset="4" :span="20" style="height: 100%;overflow-y: scroll;" ref="scrollRef" @scroll="handleScroll">
        <div>
          <div class="setting-section" id="1-1">
            <h4>日历</h4>
            <div class="setting-content">
              <calendar-search-scope-preference :info="options"/>
            </div>
          </div>
        </div>
        <div>
          <div class="setting-section" id="2-1">
            <h4>我的任务</h4>
            <div class="setting-content">
              <my-mission-task-status-preference :info="options"/>
              <my-mission-project-status-preference :info="options"/>
            </div>
          </div>
          <div class="setting-section" id="2-2">
            <h4>任务管理</h4>
            <div class="setting-content">
              <task-management-view-preference :info="options"/>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {getAllOptionsUserInfo} from "@/api/calendar";
import CalendarSearchScopePreference from "@/components/userPreference/calendarSearchPreference";
import TaskManagementViewPreference from "@/components/userPreference/taskManagementViewPreference";
import MyMissionTaskStatusPreference from "@/components/userPreference/myMissionTaskStatusPreference";
import MyMissionProjectStatusPreference from "@/components/userPreference/myMissionProjectStatusPreference";

export default {
  name: "userPreference",
  components: {
    MyMissionProjectStatusPreference,
    MyMissionTaskStatusPreference, TaskManagementViewPreference, CalendarSearchScopePreference},
  data() {
    return {
      activeMenu: '1-1',
      options: {},
      currId: null,
    }
  },
  mounted() {
    this.getAllOptionsUserInfo();
  },
  watch: {
    currId(val, oldVal) {
      if (oldVal) {
        let el = document.getElementById(oldVal);
        el.style.borderColor = '#CCCCCC';
      }
      if (val) {
        let el = document.getElementById(val);
        el.style.borderColor = '#409EFF';
        setTimeout(() => {
          el.style.borderColor = '#CCCCCC';
        }, 1500);
      }
    }
  },
  methods: {
    getAllOptionsUserInfo() {
      getAllOptionsUserInfo().then(res => {
        if (res.success) {
          this.options = res.data;
        }
      })
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleScroll(e) {
      console.log('handleScroll', e);
    },
    scrollToId(id) {
      this.currId = id;
      let el = document.getElementById(id);
      let rect = el.getBoundingClientRect();
      this.$refs.scrollRef.$el.scrollTo(0, rect.top - 100);
    }
  }
}
</script>

<style scoped lang="scss">
.setting-section {
  position: relative;
  border: 1px solid #ccc;
  margin: 20px 20px 40px;
  padding: 25px;

  > h4 {
    position: absolute;
    top: -12px;
    left: 20px;
    padding: 0 15px;
    font-weight: normal;
    background-color: white;
  }

  .setting-content {
    font-size: 14px;
    color: #333;

    >div:not(:last-of-type){
      margin-bottom: 20px;
    }
  }
}
</style>
