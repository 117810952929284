<template>
  <div>
    <div class="flexJfAc childWidth">
      <span style="width: 120px;">查看的人员范围： </span>
      <el-radio
        @change="userScopeChange"
        v-model="userSet.value.scope"
        v-for="(item, index) in typeList"
        :key="index"
        :label="item.lable"
      >{{ item.name }}
      </el-radio
      >
      <div class="" style="width: 62%" v-show="userSet.value.scope === 3">
        <span> </span>
        <el-cascader
          filterable
          clearable
          v-model="userSet.value.userIdList"
          :options="useDeptList"
          :show-all-levels="false"
          :props="{
                multiple: true,
                value: 'uid',
                label: 'realName',
                children: 'userList',
              }"
          @visible-change="getUseDeptList($event)"
          @change="setOptionUserInfo"
          style="width: 100%"
        >
        </el-cascader>
      </div>
    </div>
  </div>
</template>

<script>
import {getScheduleUseDeptList, setOptionUserInfo} from "@/api/calendar";

export default {
  name: "calendarSearchScopePreference",
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      userSetDefault: {
        name: "schedule:preference:home_display",
        value: {
          scope: 2,  // 1:所有人 2:我自己 3:指定成员
          userIdList: [],
          calendarMode: 'month',
          scheduleGroupByUser: true
        },
      },
      userSet: {
        name: "schedule:preference:home_display",
        value: {
          scope: 2,  // 1:所有人 2:我自己 3:指定成员
          userIdList: [],
          calendarMode: 'month',
          scheduleGroupByUser: true
        },
      }, //设置用户
      useDeptList: [],
      typeList: [
        {
          lable: 1,
          name: "所有人",
        },
        {
          lable: 2,
          name: "我自己",
        },
        {
          lable: 3,
          name: "指定人员",
        },
      ],
    }
  },
  mounted() {
    this.getOptionUserInfo();
  },
  activated() { 
    this.getOptionUserInfo();
  },
  watch: {
    info: {
      handler(val, oldVal) {
        if (!val) return;
        let optionValue = val[this.userSet.name];
        if (optionValue) {
          localStorage.setItem(this.userSet.name, optionValue);
          this.userSet.value = JSON.parse(optionValue) || this.userSetDefault.value;
        }
      },
      deep: true
    }
  },
  methods: {
    userScopeChange(scope) {
      if (scope * 1 !== 3) {
        this.userSet.value.userIdList = [];
        this.setOptionUserInfo();
      }
    },
    async getOptionUserInfo() {
      let item = window.localStorage.getItem(this.userSet.name);
      this.userSet.value = Object.assign({}, item ? JSON.parse(item) : this.userSetDefault.value);
      if (this.userSet.value.scope === 3) {
        await this.getUseDeptList(true);
      }
    },

    async getUseDeptList(cb) {
      if (cb) {
        const response = await getScheduleUseDeptList({});
        if (response.success) {
          let useDeptList = response.data;
          useDeptList.forEach(function (item) {
            item.uid = item.deptId;
            item.realName = item.deptName;
          });
          this.useDeptList = useDeptList;
        }
      }
    },
    setOptionUserInfo() {
      let userSet = {...this.userSet};

      userSet.value = JSON.stringify(userSet.value);

      window.localStorage.setItem(this.userSet.name, userSet.value);

      setOptionUserInfo(userSet).then((response) => {
        if (response.success) {
          console.log("日历查询偏好保存成功");
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">

</style>
