<template>
  <div>
    <span>业务状态： </span>
    <el-select
      v-model="projectStatus"
      style="width: 300px;"
      clearable
      @change="setOptionUserInfo"
    >
      <el-option
        v-for="(item, index) in projectStatusListOptions"
        :key="index"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import {setOptionUserInfo} from "@/api/calendar";

export default {
  name: "myMissionProjectStatusPreference",
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      optionName: 'task:my_mission:preference:project_workflow_condition',
      projectStatus: 1,
      projectStatusListOptions: [
        {
          value: 1,
          label: "不包含已暂停/已终止项目",
        },
        {
          value: 2,
          label: "包含已暂停/已终止项目",
        },
        {
          value: 3,
          label: "仅限已暂停/已终止项目",
        },
      ],
    }
  },
  mounted() {
    this.getOptionUserInfo();
  },
  watch: {
    info: {
      handler(val) {
        let optionValue = val[this.optionName];
        if (optionValue) {
          this.projectStatus = optionValue * 1;
          localStorage.setItem(this.optionName, optionValue);
        }
      },
      deep: true
    }
  },
  methods: {
    getOptionUserInfo() {
      let item = window.localStorage.getItem(this.optionName);
      if (item) {
        this.projectStatus = item * 1;
      }
    },
    setOptionUserInfo(val) {
      console.log(val);
      localStorage.setItem(this.optionName, val);

      let param = {
        name: this.optionName,
        value: val
      }
      setOptionUserInfo(param)
        .then(res => {
          if (res.success) {
            console.log('我的业务状态偏好保存成功');
          }
        })
    },
  }
}
</script>

<style scoped>

</style>
