<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2021-12-09 11:17:06
 * @LastEditTime: 2021-12-14 15:45:17
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/userPreference/taskManagementViewPreference.vue
-->
<template>
  <div class="text-align-center">
    <span style="color:#606266;">首选视图：&nbsp;</span>
    <el-select
      v-model="userViewType"
      @change="onChooseViewType"
      style="width: 300px;"
    >
      <el-option
        v-for="(item, index) in viewTypeOptions"
        :key="index"
        :value="item.value"
        :label="item.label"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import {setOptionUserInfo} from "@/api/calendar";

export default {
  name: "taskManagementViewPreference",
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      optionName: 'task:management:preference:view_type',
      viewTypeOptions: [
        {value: 'default', label: '系统默认'},
        {value: 'plan', label: '计划视图'},
        {value: 'track', label: '跟踪视图'}
      ],
      userViewType: 'default',
    }
  },
  mounted() {
    this.getUserViewType();
  },
  activated() { 
    this.getUserViewType();
  },
  watch: {
    info: {
      handler(val, oldVal) {
        let optionValue = val[this.optionName];
        if (optionValue) {
          this.userViewType = optionValue;
          localStorage.setItem(this.optionName, optionValue);
        }
      },
      deep: true
    }
  },
  methods: {
    getUserViewType() {
      let userViewType = window.localStorage.getItem(this.optionName);
      if (userViewType) {
        this.userViewType = userViewType;
      }
    },
    onChooseViewType(viewType) {
      //save viewType
      this.userViewType = viewType;
      localStorage.setItem(this.optionName, viewType);

      let param = {
        name: this.optionName,
        value: viewType
      }
      setOptionUserInfo(param)
        .then(res => {
          if (res.success) {
            console.log('任务管理视图偏好保存成功');
          }
        })
    },
  }
}
</script>

<style scoped lang="scss">

</style>
